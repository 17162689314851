import { Locale } from './language.model';

export class LocaleString {
	en?: string;
	ar?: string;
	ur?: string;
	fil?: string;

	constructor(init?: Partial<LocaleString>) {
		Object.assign(this, init);
	}

	toLocaleString(): string {
		const currentLang = localStorage.getItem('language') as Locale;
		return LocaleString.getValueAtSelectedLang(this, currentLang, currentLang === 'en' ? 'ar' : 'en');
	}

	static parse(jsonStr: string): LocaleString {
		if (!jsonStr) return null;

		let jsonObj: LocaleString = null;
		try {
			jsonObj = JSON.parse(jsonStr) as LocaleString;
		} catch {}

		if (!jsonObj) return jsonStr;

		return new LocaleString({ en: jsonObj.en, ar: jsonObj.ar, ur: jsonObj.ur, fil: jsonObj.fil });
	}

	static getValueAtSelectedLang(value: LocaleString, lang: Locale, failoverLang: Locale): string {
		return value[lang] || value[failoverLang] || value[Object.keys(value).find((key) => !!value[key])];
	}

	static getStringValueAtSelectedLang(value: string, lang: Locale, failoverLang: Locale): string {
		let _value = LocaleString.parse(value);

		if (typeof _value === 'string') return _value;
		return LocaleString.getValueAtSelectedLang(_value, lang, failoverLang);
	}
}
