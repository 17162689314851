// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { Language, LocaleString } from '../../../../core/_comman';
import { TranslationService } from '../services/translation.service';

/**
 * Returns locale string for provided lang form json string.
 */
@Pipe({
	name: 'locale',
})
export class ParseLocalePipe implements PipeTransform {
	constructor(private transService: TranslationService) {}

	/**
	 * Transform
	 *
	 * @param value string | LocaleString
	 * @param lang Locale
	 */
	transform(value: string | LocaleString, lang?: Language): string {
		if (!value) return '';

		// If lang not provided, then use current language.
		if (!lang) lang = this.transService.getSelectedLanguage();

		if (typeof value === 'string') {
			return LocaleString.getStringValueAtSelectedLang(value, lang.id, lang.failoverLang);
		} else {
			return LocaleString.getValueAtSelectedLang(value, lang.id, lang.failoverLang);
		}
	}
}
