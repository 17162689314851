import { InjectionToken } from '@angular/core';

/**
 * A DI Token representing the localStorage. In a browser this is the window localStorage.
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('LocalStorageToken');

/**
 * A DI Token representing the window object.
 */
export const WINDOW = new InjectionToken<Window>('WindowToken');
