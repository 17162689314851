import { Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

/**
 * Custom Validators Class
 */
export class CustomValidators extends Validators {
	static whiteSpacesRegex = /^\s*$/;

	/**
	 * @description
	 * Validator that requires the control's value be true, not empty and is not whitespaces
	 *
	 * This validator extends the Validators.required
	 *
	 * @param frmCtrl AbstractControl | FormControl
	 */
	static required(frmCtrl: AbstractControl | FormControl): ValidationErrors | null {
		return CustomValidators.noEmptyRequiredValidatorFn(frmCtrl);
	}

	/**
	 * @description Checks if control's value is empty or is whitespace
	 *
	 * @param frmCtrl AbstractControl | FormControl
	 */
	static noEmptyRequiredValidatorFn(frmCtrl: AbstractControl | FormControl) {
		const isValid = !this.whiteSpacesRegex.test(frmCtrl.value);

		if (isValid) {
			return null;
		}

		return { required: true };
	}
}
