const devServerURL = 'https://freejna-iis-dev.uaenorth.cloudapp.azure.com';

export const environment = {
	production: false,
	development: false, // used to set root folder for SSR
	IDENTITY_BASE_URL: devServerURL + '/api/identity',
	IDENTITY_V2_BASE_URL: devServerURL + '/api/identity/v2',
	SOCIAL_BASE_URL: devServerURL + '/api/social',
	SOCIAL_V2_BASE_URL: devServerURL + '/api/social/v2',
	reCapKey: '6LclwAobAAAAAMqRXUqTeR9yWTvR1jkxT1WG9g-M' /*** This key is for dev use only ***/,
	baseUrlRoutes: [devServerURL],
};
