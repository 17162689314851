import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';

const routeConfig: ExtraOptions = {
	anchorScrolling: 'disabled',
	onSameUrlNavigation: 'reload',
	scrollPositionRestoration: 'disabled',
	initialNavigation: 'enabledBlocking',
};

const routes: Routes = [
	{
		path: 'signup/organization',
		loadChildren: () =>
			import('./views/organization-signup/organization-signup.module').then(
				(m) => m.OrganizationSignupModule
			),
	},
	{
		path: 'signup/community-ambassador',
		loadChildren: () =>
			import('./views/community-ambassador/community-ambassador.module').then(
				(m) => m.CommunityAmbassadorModule
			),
	},
	{
		path: 'faq',
		loadChildren: () => import('./views/faq-page/faq.module').then((m) => m.FaqModule),
	},
	{
		path: 'content/contacts',
		loadChildren: () =>
			import('./views/important-contacts/important-contacts.module').then(
				(m) => m.ImportantContactsModule
			),
	},
	{
		path: 'cards',
		loadChildren: () => import('./views/card-base/card-base.module').then((m) => m.CardBaseModule),
	},

	{ path: 'not-found', component: NotFoundPageComponent },
	{ path: '', redirectTo: 'signup/organization', pathMatch: 'full' },
	{ path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, routeConfig)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
