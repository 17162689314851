// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// external packages
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecaptchaFormsModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

// Core Modules
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './theme/theme.module';

// Services
import { HttpIntercept, HttpUtilsService, TypesUtilsService } from './core/_base/utils';

// Config
import { environment } from 'src/environments/environment';

// Components
import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';

// CONST and functions
export function mainHttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		// Components
		AppComponent,
		NotFoundPageComponent,
	],
	imports: [
		CommonModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserTransferStateModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		ThemeModule.forRoot(),
		RecaptchaV3Module,
		RecaptchaFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: mainHttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		HttpUtilsService,
		TypesUtilsService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCapKey },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
