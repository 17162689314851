<div class="h-100 d-flex flex-column justify-content-around align-items-center">
	<div class="card rt-action-card-wrapper d-inline-flex flex-column justify-content-center pb-lg-3 pb-2">
		<div class="d-lg-none rt-freejna-logo mx-auto">
			<img src="../../../../assets/img/logo/freejna-logo.png" />
		</div>
		<div class="card-body text-wrap text-center">
			<div class=" d-none d-lg-flex justify-content-center mb-4">
				<img src="../../../../assets/img/logo/freejna-logo.png" />
			</div>
			<h1>404</h1>
            <p>{{ 'NOT_FOUND.TEXT' | translate }}</p>
		</div>
	</div>
</div>


