import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpEventType,
	HttpResponse,
	HttpHeaders,
	HttpErrorResponse,
} from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

export interface Upload {
	progress: number;
	inProgress: boolean;
}

@Injectable()
export class HttpUtilsService {
	// uploading progress
	uploadProgress$: Observable<Upload>;
	private uploadProgressSubject = new Subject<Upload>();

	constructor(private trans: TranslateService) {
		this.uploadProgress$ = this.uploadProgressSubject.asObservable();
	}

	/**
	 * get standard content-type application/json
	 */
	getHTTPHeaderStandardContentType(): HttpHeaders {
		const result = new HttpHeaders().set('Content-Type', 'application/json');
		return result;
	}

	/**
	 * Returns translated message of http error response status code.
	 *
	 * @param errResponse: HTTpErrorResponse
	 *
	 * returns string
	 */
	handleHttpError(errResponse: HttpErrorResponse): string {
		if (errResponse.status === 0) {
			return this.trans.instant('BASE.HTTP_ERR_RESPONSE_0');
		} else if (errResponse.status === 404) {
			return this.trans.instant('BASE.HTTP_ERR_RESPONSE_404');
		} else if (errResponse.status == 500) {
			return this.trans.instant('BASE.HTTP_ERR_RESPONSE_500');
		} else if (errResponse && errResponse.error) {
			let errMessage = errResponse.error;
			// in some endpoints we are receiving error as a string instead of an object
			if (typeof errMessage === 'string') return errMessage;
			else if (typeof errMessage === 'object' && errMessage.message)
				/**
				 * for other endpoints we are receiving errors as either
				 * old error object {errors:{} ,message:{}, statusCode:""}
				 * or new error object ReponseMessageModel
				 **/
				return typeof errMessage.message === 'string'
					? errMessage.message
					: errMessage.message.messages || this.trans.instant('BASE.HTTP_ERR_RESPONSE_400');
			else this.trans.instant('BASE.HTTP_ERR_RESPONSE_400');
		} else {
			return this.trans.instant('BASE.HTTP_ERR_RESPONSE_400');
		}
	}

	// custom rxjs operator used to handle the upload progress
	handleUploadProgress(): (source: Observable<HttpEvent<unknown>>) => Observable<any> {
		return (source) =>
			source.pipe(
				tap((event) => {
					// Via this API, you get access to the raw event stream.
					// Look for upload progress events.
					if (event.type === HttpEventType.UploadProgress) {
						// This is an upload progress event. Compute and show the % done:
						const percentDone = Math.round((100 * event.loaded) / event.total);
						this.uploadProgressSubject.next({ progress: percentDone, inProgress: true });
					} else if (event instanceof HttpResponse) {
						this.uploadProgressSubject.next({ progress: 100, inProgress: false });
					}
				}),
				skipWhile((event) => !(event instanceof HttpResponse))
			);
	}
}
