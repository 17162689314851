import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { fromEvent, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { LoadingScreenService, TranslationService } from 'src/app/core/_base/layout';
import { Locale } from './core/_comman';
import { SEO_EXCLUDED_ROUTES } from 'src/seo-excluded-routes';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	title = 'freejna-web';
	showLoader = false; // enable/disable loader
	loadRouterOutlet = true;
	styleLoaded = false;
	lang: Locale;
	isExcludedRoute = false;

	private _unsubscribe = new Subject<void>();

	constructor(
		public translationService: TranslationService,
		public translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private loadingScreenService: LoadingScreenService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document
	) {
		// register translations
		this.translationService.loadTranslations(['en', 'ar']);
	}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				switchMap(() => this.activatedRoute.queryParamMap),
				takeUntil(this._unsubscribe)
			)
			.subscribe((route: ParamMap) => {
				let currentRoute = this.router.url.split(/(?=\/)/, 1)[0];
				this.isExcludedRoute = SEO_EXCLUDED_ROUTES.some((route) => route.includes(currentRoute));

				this.showLoader = this.isExcludedRoute; // should be enabled only when route is excluded from SSR

				if (this.isExcludedRoute) {
					this.loadingScreenService.show();
				}

				this.lang = (route.get('lang') as Locale) || 'en';

				// set selected language based on query String
				this.translationService.setLanguage(this.lang);

				if (this.lang === this.translationService.getSelectedLanguage().id) {
					if (this.isExcludedRoute) {
						this.loadRouterOutlet = false;
					}

					this.loadLangStyles();
				} else {
					this.translationService.langChangeStart$
						.pipe(takeUntil(this._unsubscribe))
						.subscribe(() => {
							if (this.isExcludedRoute) {
								this.loadRouterOutlet = false;
							}

							this.loadLangStyles();

							setTimeout(() => {
								this.loadRouterOutlet = true;
								this.cdr.detectChanges();
							}, 100);
						});
				}
				if (this.isExcludedRoute) this.endPageLoading();
			});
	}

	loadLangStyles() {
		let headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
		let selectedLang = this.translationService.getSelectedLanguage();
		let bundleName = String(selectedLang.dir) + 'Style.css';
		let styleLink = this.document.getElementById('langCss') as HTMLLinkElement;

		// if style is not added to document, then create a new one
		if (!styleLink) {
			styleLink = this.document.createElement('link');
			styleLink.rel = 'stylesheet';
			styleLink.type = 'text/css';
			styleLink.id = 'langCss';
			styleLink.href = bundleName;
			headTag.appendChild(styleLink);

			if (this.isExcludedRoute) {
				// hide loading screen after style is loaded.
				fromEvent(styleLink, 'load').subscribe(() => {
					this.styleLoaded = true;

					this.loadRouterOutlet = true;
					this.cdr.detectChanges();

					// end the page loading.
					this.endPageLoading();
				});
			}
		}

		// Style href shouldn't be chanaged if lang direction is not changed.
		if (styleLink && !styleLink.href.includes(bundleName)) {
			styleLink.href = bundleName;
			this.styleLoaded = true;
			this.cdr.detectChanges();

			if (this.isExcludedRoute) {
				// end the page loading.
				this.endPageLoading();
			}
		}
	}

	endPageLoading() {
		if (this.styleLoaded) {
			// hide loading screen if style has been loaded
			this.loadingScreenService.hide();

			// to display back the body content
			setTimeout(() => this.document.body.classList.add('page-loaded'), 500);
		}
	}

	ngOnDestroy() {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}
}
