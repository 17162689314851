// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Components
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LoadingScreenService } from '../core/_base/layout/services/loading-screen.service';

@NgModule({
	declarations: [LoadingScreenComponent],
	imports: [
		CommonModule,

		// Material Modules
		MatProgressSpinnerModule,
	],
	exports: [LoadingScreenComponent],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		return {
			ngModule: ThemeModule,
			providers: [LoadingScreenService],
		};
	}
}
