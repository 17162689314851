import { LocaleString } from './locale-string.model';

/**
 * @param id Language code ar, en, fr, ur etc.
 * @param name Display name of the language.
 * @param dir Language writing direction
 */
export interface Language {
	id: Locale;
	name: LocaleString;
	dir: 'ltr' | 'rtl';
	failoverLang: Locale;
}

export type Locale = 'en' | 'ar' | 'ur' | 'fil';

export const SUPPORTED_LANGS: Language[] = [
	{ id: 'ar', name: { ar: 'العربية', en: 'Arabic' }, dir: 'rtl', failoverLang: 'en' },
	{ id: 'en', name: { ar: 'الإنكليزية', en: 'English' }, dir: 'ltr', failoverLang: 'ar' },
	{ id: 'ur', name: { ar: 'أوردو', en: 'Urdu' }, dir: 'rtl', failoverLang: 'ar' },
	{ id: 'fil', name: { ar: 'الفلبينية', en: 'Filipino' }, dir: 'ltr', failoverLang: 'en' },
];
