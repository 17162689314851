import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Injectable()
export class LoadingScreenService {
	private el: ElementRef;
	private stopped: boolean;

	constructor(private animationBuilder: AnimationBuilder) {}

	init(element: ElementRef) {
		this.el = element;
	}

	hide() {
		if (this.stopped) {
			return;
		}

		const player = this.animationBuilder
			.build([style({ opacity: '1' }), animate(800, style({ opacity: '0' }))])
			.create(this.el.nativeElement);

		player.onDone(() => {
			this.el.nativeElement.style.display = 'none';
			this.stopped = true;
		});

		setTimeout(() => player.play(), 300);
	}

	show() {
		if (!this.stopped) {
			return;
		}

		const player = this.animationBuilder
			.build([style({ display: '', opacity: '0' }), animate(800, style({ opacity: '1' }))])
			.create(this.el.nativeElement);

		player.onDone(() => {
			this.stopped = false;
		});

		player.play();
	}
}
