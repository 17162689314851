import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { LoadingScreenService } from '../../core/_base/layout';

@Component({
	selector: 'app-loading-screen',
	templateUrl: './loading-screen.component.html',
	styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnInit {
	loaderConfig = {
		enabled: true,
		type: 'spinner-logo',
		logo: './assets/img/logo/freejna-logo.png',
		message: 'Please wait...',
	};
	isPlatformBowser: boolean;
	@ViewChild('loadingScreen', { static: true }) loadingScreen: ElementRef;

	constructor(private loadingScreenService: LoadingScreenService, @Inject(PLATFORM_ID) platform: Object) {
		this.isPlatformBowser = isPlatformBrowser(platform);
	}

	ngOnInit() {
		// init splash screen
		this.loaderConfig.enabled = this.isPlatformBowser;
		this.loadingScreenService.init(this.loadingScreen);
	}
}
