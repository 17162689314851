import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpHeaders,
	HttpErrorResponse,
} from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { TranslationService } from '../layout/services/translation.service';
import { HttpUtilsService } from './http-utils.service';
import { LOCAL_STORAGE } from 'src/dom_tokens';

@Injectable()
export class HttpIntercept implements HttpInterceptor {
	private baseUrlRoutes = []; // list of routes to secure with accessToken
	private currentLanguage: string = 'en';
	private isPlatformServer: boolean;

	constructor(
		private httpUtils: HttpUtilsService,
		@Inject(LOCAL_STORAGE) private localStorage: any,
		@Inject(PLATFORM_ID) private platformId: Object,
		private langService: TranslationService
	) {
		this.isPlatformServer = isPlatformServer(this.platformId);
		this.baseUrlRoutes = environment.baseUrlRoutes; // list of routes to secure with accessToken
		this.getCurrentLang();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.getCurrentLang();

		if (this.baseUrlRoutes.some((uri) => request.url.startsWith(uri))) {
			var httpHeaders = this.getHTTPHeaders();
			request = request.clone({
				headers: httpHeaders,
			});
		}

		return next.handle(request).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse) {
					let errorMessage = this.httpUtils.handleHttpError(error);
					return throwError(new Error(errorMessage));
				} else if (error instanceof Error) {
					return throwError(error);
				} else {
					return throwError(new Error(error));
				}
			})
		);
	}

	private getHTTPHeaders(): HttpHeaders {
		return new HttpHeaders().set('Accept-Language', this.currentLanguage);
	}

	private getCurrentLang(): void {
		this.currentLanguage = this.isPlatformServer
			? this.langService.getSelectedLanguage().id
			: this.localStorage.getItem('language') || 'en';
	}
}
