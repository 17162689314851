import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Direction } from '@angular/cdk/bidi';
import { Language, Locale, SUPPORTED_LANGS } from '../../../_comman/_models/language.model';
import { LOCAL_STORAGE } from 'src/dom_tokens';

@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	// public properties
	langChangeStart$: Observable<void>;
	langChanged$: Observable<string>;
	directionChanged$: Observable<Direction>;

	// private properties
	private dir: Direction = 'ltr';
	private selectedLang: Language; // used with SSR
	private isPlatformBrowser: boolean;

	private langChangeStartSubject = new Subject<void>();
	private langChangedSubject = new Subject<string>();
	private directionChangedSubject = new BehaviorSubject<Direction>(this.dir);
	private htmlTag: HTMLHtmlElement;

	constructor(
		private translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) platform: Object,
		@Inject(LOCAL_STORAGE) private localStorage: any
	) {
		this.langChangeStart$ = this.langChangeStartSubject.asObservable();
		this.langChanged$ = this.langChangedSubject.asObservable();
		this.directionChanged$ = this.directionChangedSubject.asObservable();

		this.htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;

		// check if current platform is browser
		this.isPlatformBrowser = isPlatformBrowser(platform);
	}

	/**
	 * Adds list of languages to TransalteService
	 *
	 * @param locales: Locale[]
	 */
	loadTranslations(locales: Locale[]): void {
		// add new languages to the list
		this.translate.addLangs([...locales]);
	}

	/**
	 * Set current Laguage
	 *
	 * @param lang: Locale
	 */
	setLanguage(lang: Locale) {
		this.langChangeStartSubject.next();
		this.selectedLang = SUPPORTED_LANGS.find((language) => language.id === lang);
		if (!this.selectedLang)
			this.selectedLang = SUPPORTED_LANGS.find((language) => language.id === 'en');

		this.useLanguage();
	}

	getSelectedLanguage(): Language {
		return this.selectedLang;
	}

	getDirection(): Direction {
		return this.dir;
	}

	private useLanguage() {
		let langToUse =
			this.selectedLang.id === 'ur' || this.selectedLang.id === 'fil'
				? this.selectedLang.failoverLang
				: this.selectedLang.id;
		this.translate.use(langToUse);
		this.dir = this.selectedLang.dir;
		this.htmlTag.dir = this.dir;

		if (this.dir != this.directionChangedSubject.value) {
			// emit direction change value
			this.directionChangedSubject.next(this.dir);
		}

		this.langChangedSubject.next(this.getSelectedLanguage().id);

		if (this.isPlatformBrowser) {
			this.localStorage.setItem('language', this.selectedLang.id);
		}
	}
}
