import { Injectable } from '@angular/core';
import { LookupItemModel } from '../../_comman';

@Injectable()
export class TypesUtilsService {
	constructor() {}

	/**
	 * Converts enum to object
	 * @param source enum to be converted
	 * @returns object<any>
	 */
	enumToObj<T>(source: T): Object {
		let obj = {};

		Object.keys(source).forEach((item) => {
			obj[source[item] as any] = item;
		});

		return obj;
	}

	/**
	 * Convert enum to LookupItem List
	 * @param source enum with number values
	 * @example as in exchange offer
	 * @todo to be modified later to return LookupItemViewModel
	 */
	enumToList<T>(source: T, idType?: 'string' | 'number'): LookupItemModel<any>[] {
		if (idType === 'string')
			return Object.keys(source).map((key) => ({
				id: source[key],
				name: key,
			}));

		return Object.keys(source)
			.filter((x) => parseInt(x) >= 0)
			.map((key) => ({
				id: Number(key),
				name: source[key],
			}));
	}

	/**
	 * change case of provided string from CamelCase to kebab-case
	 */
	convertCamelCaseToKebabCase(value: string): string {
		return !!value
			? value[0].toLowerCase() +
					value.slice(1, value.length).replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
			: undefined;
	}
}
